import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { WindowLocation } from '@reach/router';
import { useAtom, useSetAtom } from 'jotai';

import { useUrlPageSelection } from '@hooks/useUrlPageSelection';
import { useCustomMixpanel } from '@services/Mixpanel';
import { NewPlanSelectionPage } from '@src/modules/PlanSelection/NewPlanSelectionPage';
import { Variant } from '@entities/enums';
import { getRedirectedExcessValue } from '@utils/excess';
import { excessAtom, isAgentAtom, repairAtom } from '@src/store/store';
import { getEnergyCreditTermsURL } from '@utils/EnergyCredit';

export interface HomeProps {
  location: WindowLocation;
}

export const Home = ({ location }: HomeProps) => {
  getEnergyCreditTermsURL();

  const [excess, setExcess] = useAtom(excessAtom);
  const setIsAgent = useSetAtom(isAgentAtom);
  const setRepair = useSetAtom(repairAtom);

  const mixpanel = useCustomMixpanel();

  const selectedPageFromUrl = useUrlPageSelection();

  useEffect(() => {
    if (selectedPageFromUrl) {
      return;
    }
    setIsAgent(false);
    setExcess(getRedirectedExcessValue(excess));
    setRepair(undefined);
  }, [excess, selectedPageFromUrl, setIsAgent, setRepair, setExcess]);

  useEffect(() => {
    if (selectedPageFromUrl) {
      navigate(`/${selectedPageFromUrl}`);
      return;
    }
  }, [selectedPageFromUrl]);

  if (selectedPageFromUrl) {
    return null;
  }

  return (
    <NewPlanSelectionPage location={location} mixpanel={mixpanel} variant={Variant.STANDARD} />
  );
};
