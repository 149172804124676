import { PageProps } from 'gatsby';
import { useDecision } from '@optimizely/react-sdk';

import { Home as HomeModule } from '@src/modules';
import { Home as QuotesPlatformHome } from '@src/modules/Home/QuotesPlatformHome';
import { OptimizelyFeatureFlag } from '@entities/optimizely';

const Home = (props: PageProps) => {
  const [{ enabled: isQuotesPlatform }] = useDecision(
    OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT,
    { autoUpdate: true }
  );

  if (isQuotesPlatform) {
    return <QuotesPlatformHome {...props} />;
  }

  return <HomeModule {...props} />;
};

export default Home;
